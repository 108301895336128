import React from "react";

export default function Gallery() {
  return (
    <div>
      <div className="w-full text-2xl ">
        <iframe
          title="gallery"
          className="w-full h-full "
          frameborder="0"
          src="https://lagalerie-vr.github.io/Imed/"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allowvr="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; vr"
        ></iframe>
      </div>
    </div>
  );
}
