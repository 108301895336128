import FullPageScroll from "./Pages/FullPageScroll";
import "./App.css";
import Loading from "./Components/LandingPage/Loading";
import { useEffect, useState } from "react";
import Navbar from "./Components/LandingPage/Navbar";
import Footer from "./Components/LandingPage/Footer";
import GalleryPage from "./Pages/GalleryPage";
import Solo from "./Pages/Solo";
import Pack from "./Pages/Pack";
import Artbox from "./Pages/Artbox";
import Replica from "./Pages/Replica";
import Scan from "./Pages/Scan";
import Call from "./Pages/Call";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Contact from "./Pages/Contact";
import Artists from "./Pages/Artists";
import Exhibitions from "./Pages/Exhibitions";
import Sar from "./Pages/Sar";
import Blog from "./Pages/Blog";
import Chourou from "./Pages/Chourou";
import TwistedBodies_SoloExhibition from "./Pages/TwistedBodies_SoloExhibition";
import AGlimmerOfHope_SoloExhibition from "./Pages/AGlimmerOfHope_SoloExhibition";
import A000000000001000AA011_DuoExhibition from "./Pages/A000000000001000AA011_DuoExhibition";
import Albahie from "./Pages/AlBahie"
import Apparemment from "./Pages/Apparemment";
import SpaceI from "./Pages/SpaceI";
import SpaceII from "./Pages/SpaceII";
import Artbox1 from "./Pages/Artbox1";
import Replica1 from "./Pages/Replica1";
import Imed from "./Pages/Imed"
import UnseenCall from "./Components/LandingPage/UnseenCall";
import SoloExhib3 from "./Components/LandingPage/soloExhib3";
import Artboxcall from "./Components/LandingPage/ArtboxCall";
import Faq from "./Pages/Faq";

function App() {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 3500);
  }, []);

  return loader ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="*" element={<FullPageScroll />} />
        <Route path="/" element={<FullPageScroll />} />
        <Route path="/unseen" element={<GalleryPage />} />
        <Route path="/Solo" element={<Solo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/scan" element={<Scan />} />
        <Route path="/form" element={<Pack />} />
        <Route path="/artbox" element={<Artbox />} />
        <Route path="/TheSuspendedMoment" element={<Artbox1 />} />
        <Route path="/replica" element={<Replica />} />
        <Route path="/replica1" element={<Replica1 />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/opencall" element={<Call />} />
        <Route path="/unseenCall" element={<UnseenCall />} />
        <Route path="/artboxcall" element={<Artboxcall />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/exhibitions" element={<Exhibitions />} />
        <Route path="/sar" element={<Sar />} />
        <Route path="/Infinity" element={<Chourou />} />
        <Route path="/AGlimmerOfHope" element={<AGlimmerOfHope_SoloExhibition />} />
        <Route path="/appartement" element={<Apparemment />} />
        <Route path="/A000000000001000AA011" element={<A000000000001000AA011_DuoExhibition />} />
        <Route path="/TwistedBodies" element={<TwistedBodies_SoloExhibition />} />
        <Route path="/SpaceI" element={<SpaceI />} />
        <Route path="/SpaceII" element={<SpaceII />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/ArtBridge" element={<SoloExhib3 />} />
        <Route path="/AlBahieMoco" element={<Albahie />} />
        <Route path="/Imed" element={<Imed />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
