import AGlimmerOfHope_SoloExhibition from "../Assets/exhibitions/AGlimmerOfHope_SoloExhibition.webp";
import TwistedBodies_SoloExhibition from "../Assets/exhibitions/TwistedBodies_SoloExhibition.webp";
import A000000000001000AA011_DuoExhibition from "../Assets/exhibitions/A000000000001000AA011_DuoExhibition.webp";
import Unseen from "../Assets/exhibitions/unseen.webp";
import SoloExhibition from "../Assets/exhibitions/SoloExhibition.webp";
import Infinity from "../Assets/exhibitions/Infinity.webp";
import Albahie from "../Assets/Albahie.jpg";
import Imed from "../Assets/Imed.jpg"


export const exhibitonsCurr = [
  {
    name: "Imed Jemaiel | Solo Exhibition",
    date: "25/02/2025",
    imageUrl: Imed,
    link: "/imed",
  },
  {
    name: "Al Bahie | Auction House Modern & Contemporary Arab Art",
    date: "09/12/2024",
    imageUrl: Albahie,
    link: "/AlBahieMoco",
  },
  {
        name: "Twisted Bodies | Solo Exhibition",
        date: "02/04/2024",
        imageUrl: TwistedBodies_SoloExhibition,
        link: "/TwistedBodies",
      },    {
        name: "A000000000001000AA011 | Duo Exhibition",
        date: "02/04/2024",
        imageUrl: A000000000001000AA011_DuoExhibition,
        link: "/A000000000001000AA011",
      },    {
        name: "A Glimmer Of Hope | Solo Exhibition",
        date: "02/04/2024",
        imageUrl: AGlimmerOfHope_SoloExhibition,
        link: "/AGlimmerOfHope",
      },
      {
        name: "Infinity | Solo exhibition",
        date: "08/02/2024",
        imageUrl: Infinity,
        link: "/Infinity",
      },
    
      {
        name: "The Suspended Moment | Solo exhibition",
        date: "21/10/2023",
        imageUrl: SoloExhibition,
        link: "/TheSuspendedMoment",
      },
      {
        name: "UNSEEN | Virtual exhibition",
        date: "22/04/2023",
        imageUrl: Unseen,
        link: "/unseen",
      },  
    
];
